import { request, noTimeOutReq } from '@/utils/request.js'

//展示所有学校
export function getAllSchool (pageIndex, pageSize) {
  return request({
    url: `/sys/org/school/page/${pageIndex}/${pageSize}/list`,
    method: 'post',
  })
}

//新增学校
export function addNewSchool (data) {
  return request({
    url: `/sys/org/school/add`,
    method: 'post',
    data
  })
}

//编辑学校
export function editSchool (data) {
  return request({
    url: `/sys/org/school/update`,
    method: 'post',
    data
  })
}

//展示某个学校所有的班级
export function getSchoolClass (sysOrgSchoolId) {
  return request({
    url: `/sys/org/school/class/school/${sysOrgSchoolId}/list`,
    method: 'post',
  })
}

//删除学校下面的某个班级
export function delSchoolClass (data) {
  return request({
    url: `/sys/org/school/class/delete`,
    method: 'post',
    data
  })
}

//编辑某个班级信息
export function editClassInfo (data) {
  return request({
    url: `/sys/org/school/class/update`,
    method: 'post',
    data
  })
}

//新增班级
export function addClass (data) {
  return request({
    url: `/sys/org/school/class/add`,
    method: 'post',
    data
  })
}

//删除学校
export function delSchool (data) {
  return request({
    url: `/sys/org/school/delete`,
    method: 'post',
    data
  })
}

//展示某个地区下面的所有学校
export function getSchoolSite (pageIndex, pageSize, data) {
  return request({
    url: `/sys/org/school/area/page/${pageIndex}/${pageSize}/list`,
    method: 'post',
    data
  })
}

<template>
  <div>
    <el-row>
      <el-button
        v-throttle
        icon="el-icon-circle-plus-outline"
        type="primary"
        @click="addSchoolForm"
        size="medium"
        >添加</el-button
      >
      <!-- <el-upload
        style="display:inline-block;margin:0 10px"
        :limit="1"
        action="https://jsonplaceholder.typicode.com/posts/"
        :http-request="uploadBtn"
        :show-file-list="false"
      >
        <el-button v-throttle  size="medium" type="primary" icon="el-icon-upload2">导入</el-button>
      </el-upload>-->
      <el-button
        v-throttle
        size="medium"
        type="primary"
        @click="
          () => {
            this.$refs.uploadExcelCom.dialogBox = true
            this.uploadMsg = ''
          }
        "
        icon="el-icon-upload2"
        >导入</el-button
      >
      <el-button
        v-throttle
        size="medium"
        type="primary"
        @click="downloadBtn"
        icon="el-icon-download"
        >下载导入模板</el-button
      >
      <el-button
        v-throttle
        icon="el-icon-delete"
        type="danger"
        size="medium"
        @click="batchDel"
        >批量删除</el-button
      >
    </el-row>
    <el-row>
      <el-form :inline="true" :model="formInline" class="demo-form-inline">
        <el-form-item label="名称" class="formItemBoxStyle">
          <el-input v-model="formInline.user" @blur="areaScreen"></el-input>
        </el-form-item>
        <el-form-item label="县区" class="formItemBoxStyle">
          <el-select
            v-model="formInline.areaValue"
            placeholder="全部"
            @change="areaScreen"
          >
            <el-option
              v-for="item in areaSelectData"
              :key="item.orgId"
              :label="item.orgName"
              :value="item.orgId"
            ></el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <el-button v-throttle type="primary" size="medium" @click="areaScreen"
            >查询</el-button
          >
          <el-button v-throttle size="medium" @click="resetScreen"
            >重置</el-button
          >
        </el-form-item>
      </el-form>
    </el-row>
    <!--表格  -->
    <el-table
      ref="multipleTable"
      :data="tableData"
      tooltip-effect="dark"
      style="width: 100%"
      @selection-change="handleSelectionChange"
      header-align="center"
      border
      :header-cell-style="{ background: '#DFE6EC' }"
      stripe
      v-loading="loadingTable"
    >
      <el-table-column
        align="center"
        type="selection"
        width="55"
      ></el-table-column>
      <!-- <el-table-column label="日期" width="120">
        <template slot-scope="scope">{{ scope.row.date }}</template>
      </el-table-column>-->
      <el-table-column
        align="center"
        prop="name"
        label="名称"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="code"
        label="代码"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="sysOrgAreaName"
        label="区县"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="userName"
        label="联系人"
        width="180"
      ></el-table-column>
      <el-table-column
        align="center"
        prop="phone"
        label="联系电话"
        width="180"
      ></el-table-column>
      <el-table-column align="center" label="操作" width="300px">
        <template slot-scope="scope">
          <el-button
            v-throttle
            size="mini"
            @click="handleEdit(scope.$index, scope.row)"
            type="success"
            icon="el-icon-edit"
            >编辑</el-button
          >
          <el-button
            v-throttle
            size="mini"
            @click="schoolClassEdit(scope.row)"
            type="primary"
            icon="el-icon-edit"
            >编辑班级</el-button
          >
          <el-button
            v-throttle
            size="mini"
            type="danger"
            @click="handleDelete(scope.$index, scope.row)"
            icon="el-icon-delete"
            >删除</el-button
          >
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="numOfPage"
      :page-sizes="[10, 20, 50, 100, 150, 200]"
      :page-size="sizeOfPage"
      layout="total, sizes, prev, pager, next, jumper"
      :total="pageTotal"
    ></el-pagination>
    <!-- 添加学校弹框 -->
    <el-dialog
      class="addPlace"
      title="添加学校"
      :visible.sync="dialogAddSite"
      width="40%"
      v-if="dialogAddSite"
    >
      <el-form
        :model="addPlaceForm"
        :rules="rules"
        ref="addPlaceForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <div class="addAll">
          <el-form-item label="名称" prop="areaName">
            <el-input
              v-model="addPlaceForm.areaName"
              placeholder
              size="small"
              maxlength="12"
            ></el-input>
          </el-form-item>
          <el-form-item label="代码" prop="areaId">
            <el-input
              v-model="addPlaceForm.areaId"
              placeholder
              size="small"
              maxlength="16"
            ></el-input>
          </el-form-item>
          <el-form-item label="区县" prop="areaValue">
            <el-select
              v-model="addPlaceForm.areaValue"
              placeholder="请选择"
              style="width: 85%; margin-left: 15px"
            >
              <el-option
                v-for="item in areaOptions"
                :key="item.orgId"
                :label="item.orgName"
                :value="item.orgId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="经度">
            <el-input
              v-model="addPlaceForm.addLng"
              placeholder
              size="small"
              maxlength="10"
              oninput="value=value.replace(/[^\d.]/g,'')"
              @blur="salaryChangeAddLng"
            ></el-input>
          </el-form-item>
          <el-form-item label="纬度">
            <el-input
              v-model="addPlaceForm.addLat"
              placeholder
              size="small"
              maxlength="10"
              oninput="value=value.replace(/[^\d.]/g,'')"
              @blur="salaryChangeAddLat"
            ></el-input>
          </el-form-item>
          <el-form-item label="通讯地址" prop="addAdress">
            <el-input
              v-model="addPlaceForm.addAdress"
              placeholder
              size="small"
              maxlength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人">
            <el-input
              v-model="addPlaceForm.userName"
              placeholder
              size="small"
              maxlength="18"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系电话">
            <el-input
              v-model="addPlaceForm.userPhone"
              placeholder
              size="small"
              maxlength="18"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button
            v-throttle
            type="primary"
            size="medium"
            @click="addSchool('addPlaceForm')"
            >保 存</el-button
          >
          <el-button v-throttle size="medium" @click="dialogAddSite = false"
            >取 消</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 添加班级弹框 -->
    <el-dialog
      class="addPlace"
      title="添加班级"
      :visible.sync="dialogAddRoom"
      width="40%"
      v-if="dialogAddRoom"
    >
      <el-form
        :model="addRoomForm"
        :rules="rules"
        ref="addRoomForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <div class="addAll">
          <el-form-item label="名称" prop="roomName">
            <el-input
              v-model="addRoomForm.roomName"
              placeholder
              size="small"
              maxlength="12"
            ></el-input>
          </el-form-item>
          <el-form-item label="编号" prop="roomId">
            <el-input
              v-model="addRoomForm.roomId"
              placeholder
              size="small"
              maxlength="8"
            ></el-input>
          </el-form-item>
          <el-form-item label="年级">
            <el-select
              size="small"
              v-model="value"
              placeholder
              style="width: 85%; margin-left: 15px"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开始年度" prop="startYear">
            <el-input
              v-model="addRoomForm.startYear"
              placeholder
              size="small"
              oninput="value=value.replace(/[^0-9]/g,'')"
              @blur="salaryAddStartYear"
            ></el-input>
          </el-form-item>
          <el-form-item label="学生总数">
            <el-input
              v-model="addRoomForm.studentTotal"
              placeholder
              size="small"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button
            v-throttle
            type="primary"
            size="medium"
            @click="addSchoolClass('addRoomForm')"
            >保 存</el-button
          >
          <el-button v-throttle size="medium" @click="dialogAddRoom = false"
            >取 消</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <!-- 班级管理 -->
    <el-dialog
      class="addPlace"
      title="编辑班级"
      :visible.sync="dialogEditPlaceRoom"
      width="40%"
    >
      <div class="addAll">
        <div class="special">
          <span class="spanText" style="float: left; margin-top: 10px"
            >班级管理</span
          >
          <div class="outLayer">
            <!-- 小卡片 -->
            <div
              ref="card"
              v-for="(v, i) in examRoom"
              :key="i"
              class="roomCard"
            >
              <span class="cardText" @click="editClass(v)">{{ v.name }}</span>
              <el-popconfirm title="确定删除该班级吗" @confirm="delCard(i, v)">
                <span class="close" slot="reference"></span>
              </el-popconfirm>
            </div>
          </div>
        </div>
        <el-button v-throttle type="primary" size="medium" @click="addClassRoom"
          >添加班级</el-button
        >
      </div>
      <!-- <span slot="footer" class="dialog-footer">
        <el-row type="flex" justify="center">
          <el-button v-throttle  type="primary" size="medium">保 存</el-button>
          <el-button v-throttle  size="medium" @click="dialogEditPlaceRoom = false">取 消</el-button>
        </el-row>
      </span>-->
    </el-dialog>
    <!-- 编辑班级信息 -->
    <el-dialog
      class="addPlace"
      title="班级信息编辑"
      :visible.sync="dialogEditClass"
      width="40%"
      v-if="dialogEditClass"
    >
      <el-form
        :model="editClassInfo"
        :rules="rules"
        ref="editClassInfo"
        label-width="100px"
        class="demo-ruleForm"
      >
        <div class="addAll">
          <el-form-item label="名称" prop="className">
            <el-input
              v-model="editClassInfo.className"
              placeholder
              size="small"
              maxlength="12"
            ></el-input>
          </el-form-item>
          <el-form-item label="编号" prop="classId">
            <el-input
              v-model="editClassInfo.classId"
              placeholder
              maxlength="8"
              size="small"
              oninput="value=value.replace(/[^0-9]/g,'')"
            ></el-input>
          </el-form-item>
          <el-form-item label="年级">
            <el-select
              size="small"
              v-model="value"
              placeholder
              style="width: 85%; margin-left: 15px"
            >
              <el-option
                v-for="item in options"
                :key="item.value"
                :label="item.label"
                :value="item.value"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="开始年度" prop="editStartYear">
            <el-input
              v-model="editClassInfo.editStartYear"
              placeholder
              size="small"
              maxlength="4"
            ></el-input>
          </el-form-item>
          <el-form-item label="学生总数">
            <el-input
              v-model="editClassInfo.editStudentTotal"
              placeholder
              size="small"
              maxlength="9"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button
            v-throttle
            type="primary"
            size="medium"
            @click="editSchoolClass('editClassInfo')"
            >保 存</el-button
          >
          <el-button v-throttle size="medium" @click="dialogEditClass = false"
            >取 消</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>

    <!-- 编辑学校 -->
    <el-dialog
      class="addPlace"
      title="编辑学校"
      :visible.sync="dialogEditSite"
      width="40%"
      v-if="dialogEditSite"
    >
      <el-form
        :model="editPlaceForm"
        :rules="rules"
        ref="editPlaceForm"
        label-width="100px"
        class="demo-ruleForm"
      >
        <div class="addAll">
          <el-form-item label="名称" prop="editAreaName">
            <el-input
              v-model="editPlaceForm.editAreaName"
              placeholder
              size="small"
              maxlength="12"
            ></el-input>
          </el-form-item>
          <el-form-item label="代码">
            <el-input
              v-model="editPlaceForm.editAreaId"
              placeholder
              size="small"
              maxlength="16"
            ></el-input>
          </el-form-item>
          <el-form-item label="区县">
            <el-select
              v-model="editAreaValue"
              placeholder="请选择"
              style="width: 85%; margin-left: 15px"
            >
              <el-option
                v-for="item in editAreaOptions"
                :key="item.orgId"
                :label="item.orgName"
                :value="item.orgId"
              ></el-option>
            </el-select>
          </el-form-item>
          <el-form-item label="经度">
            <el-input
              v-model="editPlaceForm.editLng"
              placeholder
              size="small"
              maxlength="10"
              oninput="value=value.replace(/[^\d.]/g,'')"
              @blur="salaryChangeEditLng"
            ></el-input>
          </el-form-item>
          <el-form-item label="纬度">
            <el-input
              v-model="editPlaceForm.editLat"
              placeholder
              size="small"
              maxlength="10"
              oninput="value=value.replace(/[^\d.]/g,'')"
              @blur="salaryChangeEditLat"
            ></el-input>
          </el-form-item>
          <el-form-item label="通讯地址" prop="editAdress">
            <el-input
              v-model="editPlaceForm.editAdress"
              placeholder
              size="small"
              maxlength="50"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系人">
            <el-input
              v-model="editPlaceForm.editUserName"
              placeholder
              size="small"
              maxlength="20"
            ></el-input>
          </el-form-item>
          <el-form-item label="联系电话">
            <el-input
              v-model="editPlaceForm.editUserPhone"
              placeholder
              size="small"
              maxlength="20"
            ></el-input>
          </el-form-item>
        </div>
        <el-form-item>
          <el-button
            v-throttle
            type="primary"
            size="medium"
            @click="preEditSchool('editPlaceForm')"
            >保 存</el-button
          >
          <el-button v-throttle size="medium" @click="dialogEditSite = false"
            >取 消</el-button
          >
        </el-form-item>
      </el-form>
    </el-dialog>
    <upload-excel-com
      @uploadFunc="uploadBtn"
      ref="uploadExcelCom"
      :uploadMsg="uploadMsg"
      :loading="uploadLoading"
    ></upload-excel-com>
  </div>
</template>

<script>
import { downloadSchool, importExcelSchool } from '@/api/importExport.js'
import {
  getAllSchool,
  addNewSchool,
  editSchool,
  getSchoolClass,
  delSchoolClass,
  addClass,
  delSchool,
  editClassInfo,
  getSchoolSite,
} from '@/api/mechanism/examSchool.js'
import { getAllArea, getAreaSite } from '@/api/mechanism/examArea.js'
export default {
  // 考点管理
  name: 'examPlaceManage',
  data() {
    var yearsRule = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('年份不能为空'))
      }
      setTimeout(() => {
        if (value < 2018) {
          callback(new Error('请填写实际年份'))
        } else if (value > 2030) {
          callback(new Error('请填写实际年份'))
        } else {
          callback()
        }
      }, 100)
    }
    var numberRule = (rule, value, callback) => {
      if (!value) {
        return callback(new Error('编号不能为空'))
      }
      setTimeout(() => {
        if (!this.isNumber(value)) {
          callback(new Error('编号必须为数字'))
        } else {
          callback()
        }
      }, 100)
    }
    return {
      uploadMsg: '',
      uploadLoading: false,
      options: [
        {
          value: 1,
          label: '一年级',
        },
        {
          value: 2,
          label: '二年级',
        },
        {
          value: 3,
          label: '三年级',
        },
        {
          value: 4,
          label: '四年级',
        },
        {
          value: 5,
          label: '五年级',
        },
        {
          value: 6,
          label: '六年级',
        },
        {
          value: 7,
          label: '七年级',
        },
        {
          value: 8,
          label: '八年级',
        },
        {
          value: 9,
          label: '九年级',
        },
      ],
      //分页
      pageTotal: 0,
      //每页条数公共变量
      sizeOfPage: 10,
      //当前页数
      numOfPage: 1,
      //添加考点区县选择窗口
      areaOptions: [],
      //编辑考点区县选择窗口
      editAreaOptions: [],
      //下拉框区县数据
      areaSelectData: [],
      value: 1,
      editAreaValue: '',
      formInline: {
        user: '',
        areaValue: 0,
      },
      rules: {
        roomName: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        roomId: [{ validator: numberRule, trigger: 'blur', required: true }],
        classId: [{ validator: numberRule, trigger: 'blur', required: true }],
        areaName: [{ required: true, message: '请输入名称', trigger: 'blur' }],
        areaId: [{ required: true, message: '请输入代码', trigger: 'blur' }],
        areaValue: [
          { required: true, message: '请选择区县', trigger: 'change' },
        ],
        editAreaName: [
          { required: true, message: '请输入名称', trigger: 'blur' },
        ],
        className: [
          { required: true, message: '请输入班级名称', trigger: 'blur' },
        ],
        startYear: [{ required: true, validator: yearsRule, trigger: 'blur' }],
        editStartYear: [
          { required: true, validator: yearsRule, trigger: 'blur' },
        ],
        addLat: [{ required: true, message: '请输入纬度', trigger: 'blur' }],
        addLng: [{ required: true, message: '请输入经度', trigger: 'blur' }],
        addAdress: [
          { required: true, message: '请输入通讯地址', trigger: 'blur' },
        ],
        editLat: [{ required: true, message: '请输入纬度', trigger: 'blur' }],
        editLng: [{ required: true, message: '请输入经度', trigger: 'blur' }],
        editAdress: [
          { required: true, message: '请输入通讯地址', trigger: 'blur' },
        ],
      },
      tableData: [],
      currentPage4: 1,
      examRoom: '',
      //编辑班级时的某个主键
      editPlaceId: '',
      //弹窗开关变量
      dialogAddSite: false,
      dialogAddRoom: false,
      dialogEditSite: false,
      dialogEditPlaceRoom: false,
      dialogEditClass: false,
      //添加学校-Site窗口
      addPlaceForm: {
        areaName: '',
        areaId: '',
        position: '',
        userName: '',
        userPhone: '',
        areaValue: '',
        addLng: '',
        addLat: '',
        addAdress: '',
      },
      //添加班级-Room窗口
      addRoomForm: {
        roomName: '',
        roomId: '',
        subject: '',
        studentTotal: '',
        startYear: '',
      },
      //编辑学校
      editPlaceForm: {
        editAreaName: '',
        editAreaId: '',
        editPosition: '',
        editUserName: '',
        editUserPhone: '',
        /*公共变量*/
        //sysOrgExamPlaceId
        examPlaceId: '',
        editLng: '',
        editLat: '',
        editAdress: '',
      },
      //编辑班级信息
      editClassInfo: {
        className: '',
        classId: '',
        editGrade: '',
        editStudentTotal: '',
        editStartYear: '',
        /*公共变量*/
        //sysOrgSchoolClassId
        schoolId: '',
        schoolClassId: '',
      },
      loadingTable: false,
    }
  },
  mounted() {
    this.showAllSchool()
    this.getSelectInfo()
  },
  methods: {
    // 导入
    uploadBtn(fd) {
      this.uploadLoading = true
      // let fd = new FormData()
      // fd.append('file', item.file)
      // console.log('fd', fd)
      importExcelSchool(fd)
        .then((res) => {
          this.uploadMsg = ''
          console.log('res', res)
          if (res.success) {
            // this.$message.success('导入成功');
            this.$message({
              showClose: true,
              message: '导入成功',
              type: 'success',
              duration: 10000,
            })
            this.showAllSchool()
            this.getSelectInfo()
            this.$refs.uploadExcelCom.handleClose()
          } else {
            // this.$message.warning(res.msg)
            this.uploadMsg = res.msg
            this.$refs.uploadExcelCom.clearFile()
          }
          this.uploadLoading = false
        })
        .catch((err) => {
          this.uploadLoading = false
        })
    },
    // 下载excel模板
    downloadBtn() {
      downloadSchool().then((res) => {
        console.log('下载excel模板', res)
        if (res.success) {
          this.$message.success('下载成功')
          window.open(this.$apiAddress(res.data))
        } else {
          this.$message.error('下载失败：' + res.msg)
        }
      })
    },
    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row)
        })
      } else {
        this.$refs.multipleTable.clearSelection()
      }
    },
    handleSelectionChange(val) {
      this.multipleSelection = val
    },
    handleSizeChange(val) {
      console.log(`每页 ${val} 条`)
      this.sizeOfPage = val
      this.showAllSchool()
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`)
      this.numOfPage = val
      this.showAllSchool()
    },

    //输入框失去焦点时，会把value值赋值给v-model绑定变量，使两者保持一致
    salaryChangeAddLng(e) {
      this.addPlaceForm.addLng = e.target.value
    },

    salaryChangeAddLat(e) {
      this.addPlaceForm.addLat = e.target.value
    },

    salaryChangeEditLng(e) {
      this.editPlaceForm.editLng = e.target.value
    },

    salaryChangeEditLat(e) {
      this.editPlaceForm.editLat = e.target.value
    },

    salaryAddStartYear(e) {
      this.addRoomForm.startYear = e.target.value
    },

    //重置表单
    dialogClose(formName) {
      this.$refs[formName].clearValidate()
    },

    //顶部地区下拉框获取数据
    getSelectInfo() {
      getAreaSite().then((res) => {
        let { code, data, message } = res
        if (code == 200) {
          data.childrenOrg.unshift({ orgId: 0, orgName: '全部' })
          this.areaSelectData = data.childrenOrg
        } else {
          console.log('地区筛选下拉框数据获取失败')
        }
      })
    },

    //按地区或名字筛选
    areaScreen(type) {
      const ScreenParam = new FormData()
      ScreenParam.append('sysOrgAreaId', this.formInline.areaValue)
      ScreenParam.append('query', this.formInline.user)
      console.log(ScreenParam)
      //type!=2 说明是第一次进行筛选 即把页数和条数都恢复默认1/10
      //type=2 为了跳过恢复默认，每次改变页数或者条数都会触发此方法
      if (type != 2) {
        this.numOfPage = 1
        this.sizeOfPage = 10
      }
      getSchoolSite(this.numOfPage, this.sizeOfPage, ScreenParam).then(
        (res) => {
          let { code, data } = res
          if (code == 200) {
            this.tableData = data
            this.pageTotal = res.total
          }
        }
      )
    },

    //重置筛选-点击刷新图标按钮
    resetScreen() {
      this.formInline.user = ''
      this.formInline.areaValue = 0
      this.sizeOfPage = 10
      this.numOfPage = 1
      this.showAllSchool()
    },

    //获取所有学校
    showAllSchool() {
      this.loadingTable = true
      getSchoolSite(this.numOfPage, this.sizeOfPage).then((res) => {
        let { code, data, message } = res
        this.loadingTable = false
        if (code == 200) {
          this.tableData = data
          this.pageTotal = res.total
        } else {
        }
      })
    },

    //添加学校-点击添加(弹出添加窗口)
    addSchoolForm() {
      this.dialogAddSite = true
      const AllAreaParam = new FormData()
      AllAreaParam.append('getExamPlace', 0)
      AllAreaParam.append('getSchool', 0)
      getAllArea(AllAreaParam).then((res) => {
        console.log('addSite', res)
        let { code, data, message } = res
        if (code == 200) {
          console.log('add', data.childrenOrg)
          this.areaOptions = data.childrenOrg
          //清空表单
          this.addPlaceForm.areaValue = ''
          this.addPlaceForm.areaName = ''
          this.addPlaceForm.areaId = ''
          this.addPlaceForm.userName = ''
          this.addPlaceForm.userPhone = ''
          this.addPlaceForm.addLng = ''
          this.addPlaceForm.addLat = ''
          this.addPlaceForm.addAdress = ''
        }
      })
    },

    //添加学校-保存按钮
    addSchool(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const schoolInfoParam = {
            name: this.addPlaceForm.areaName,
            code: this.addPlaceForm.areaId,
            userName: this.addPlaceForm.userName,
            sysOrgAreaId: this.addPlaceForm.areaValue,
            phone: this.addPlaceForm.userPhone,
            lat: this.addPlaceForm.addLat,
            lng: this.addPlaceForm.addLng,
            address: this.addPlaceForm.addAdress,
          }
          console.log(schoolInfoParam)
          addNewSchool(schoolInfoParam).then((res) => {
            let { code, data, msg } = res
            if (code == 200) {
              console.log(data)
              this.$message.success('添加学校成功')
              this.dialogAddSite = false
              this.showAllSchool()
            } else {
              this.$message.error(msg)
            }
          })
        } else {
        }
      })
    },

    //编辑学校-点击编辑(弹出编辑窗口)
    handleEdit(index, row) {
      // getExamRoom(1, 10, row.sysOrgExamPlaceId).then(res => {
      //   let { code, data, message } = res
      //   console.log('examroom', data);
      //   this.examRoom = data
      // })
      //打开弹窗
      console.log('rowrowrow', row)
      this.dialogEditSite = true
      //拿到区县选择下拉框数组
      const AllAreaParam = new FormData()
      AllAreaParam.append('getExamPlace', 0)
      AllAreaParam.append('getSchool', 0)
      getAllArea(AllAreaParam).then((res) => {
        console.log('addSite', res)
        let { code, data, message } = res
        if (code == 200) {
          this.editAreaOptions = data.childrenOrg
        }
      })
      this.editPlaceForm.editAreaName = row.name
      this.editPlaceForm.editAreaId = row.code
      //为下拉框默认value赋值
      this.editAreaValue = row.sysOrgAreaId
      this.editPlaceForm.editUserName = row.userName
      this.editPlaceForm.editUserPhone = row.phone
      //标记主键 -- 为保存考点编辑接口创建变量
      ;(this.editPlaceId = row.sysOrgSchoolId),
        //
        (this.editPlaceForm.editLng = row.lng)
      this.editPlaceForm.editLat = row.lat
      this.editPlaceForm.editAdress = row.address
    },

    //编辑学校-点击保存
    preEditSchool(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const editSchoolParam = {
            name: this.editPlaceForm.editAreaName,
            code: this.editPlaceForm.editAreaId,
            sysOrgAreaId: this.editAreaValue,
            userName: this.editPlaceForm.editUserName,
            phone: this.editPlaceForm.editUserPhone,
            sysOrgSchoolId: this.editPlaceId,
            lat: this.editPlaceForm.editLat,
            lng: this.editPlaceForm.editLng,
            address: this.editPlaceForm.editAdress,
          }
          editSchool(editSchoolParam).then((res) => {
            let { code, data, msg } = res
            if (code == 200) {
              this.$message.success('编辑学校成功')
              this.dialogEditSite = false
              this.showAllSchool()
            } else {
              this.$message.error(msg)
            }
          })
        } else {
          this.$message.error('请按照提示输入完整信息')
          return false
        }
      })
    },

    //编辑班级-点击编辑(弹出比编辑班级窗口)
    schoolClassEdit(row) {
      this.dialogEditPlaceRoom = true
      this.examPlaceId = row.sysOrgSchoolId
      getSchoolClass(row.sysOrgSchoolId).then((res) => {
        let { code, data, message } = res
        console.log(data)
        this.examRoom = data
      })
    },

    //删除班级
    delCard(index, value) {
      let classList = []
      classList.push(value.sysOrgSchoolClassId)
      delSchoolClass(classList).then((res) => {
        let { code, success, msg } = res
        if (success) {
          this.$message.success('删除班级成功')
          this.examRoom.splice(index, 1)
        } else {
          this.$message.error(msg)
        }
      })
    },

    //添加班级按钮-打开弹窗
    addClassRoom() {
      this.dialogAddRoom = true
      //清空表单
      this.value = 1
      this.addRoomForm.roomName = ''
      this.addRoomForm.roomId = ''
      this.addRoomForm.studentTotal = ''
      this.addRoomForm.startYear = ''
    },

    //添加班级
    addSchoolClass(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const schoolClassParam = {
            name: this.addRoomForm.roomName,
            num: parseInt(this.addRoomForm.roomId),
            grade: this.value,
            studentCount: parseInt(this.addRoomForm.studentTotal),
            startYear: this.addRoomForm.startYear,
            sysOrgSchoolId: this.examPlaceId,
          }
          addClass(schoolClassParam).then((res) => {
            let { code, data, msg } = res
            if (code == 200) {
              this.$message.success('添加班级成功')
              console.log(data)
              this.dialogAddRoom = false
              getSchoolClass(this.examPlaceId).then((res) => {
                let { code, data } = res
                console.log(data)
                this.examRoom = data
              })
            } else {
              this.$message.error(msg)
            }
          })
        } else {
          return false
        }
      })
    },

    //删除学校
    handleDelete(index, row) {
      this.$confirm('确定删除该学校?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning',
      })
        .then(() => {
          console.log(row)
          let schoolList = []
          schoolList.push(row.sysOrgSchoolId)
          delSchool(schoolList).then((res) => {
            let { success, data, msg } = res
            if (success) {
              this.showAllSchool()
              this.$message({
                type: 'success',
                message: '删除成功!',
              })
            } else {
              this.$message.error(msg)
            }
          })
        })
        .catch(() => {})
    },

    //学校批量删除
    batchDel() {
      console.log(this.$refs.multipleTable.selection.length, '1111')
      if (this.$refs.multipleTable.selection.length > 0) {
        this.$confirm('确定删除勾选的学校?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        })
          .then(() => {
            var delDataList = []
            this.$refs.multipleTable.selection.forEach((Ele, index) => {
              delDataList.push(Ele.sysOrgSchoolId)
            })
            delSchool(delDataList).then((res) => {
              let { success, data, msg } = res
              if (success) {
                this.showAllSchool()
                this.$message({
                  type: 'success',
                  message: '批量删除成功!',
                })
              } else {
                this.$message.error(msg)
              }
            })
          })
          .catch(() => {})
      } else {
        this.$message.error('请先勾选需要删除的学校')
      }
    },

    //编辑某个班级信息(点击打开编辑某个班级窗口)
    editClass(val) {
      this.dialogEditClass = true
      this.editClassInfo.className = val.name
      this.editClassInfo.classId = val.num
      this.value = val.grade
      this.editClassInfo.editStudentTotal = val.studentCount
      this.editClassInfo.editStartYear = val.startYear
      //学校ID 为下面接口提供参数
      this.editClassInfo.schoolClassId = val.sysOrgSchoolClassId
      this.editClassInfo.schoolId = val.sysOrgSchoolId
    },

    //编辑某个班级信息-点击保存按钮
    editSchoolClass(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          let ClassInfoParam = {
            name: this.editClassInfo.className,
            num: this.editClassInfo.classId,
            grade: this.value,
            studentCount: this.editClassInfo.editStudentTotal,
            startYear: this.editClassInfo.editStartYear,
            sysOrgSchoolClassId: this.editClassInfo.schoolClassId,
            sysOrgSchoolId: this.editClassInfo.schoolId,
          }
          editClassInfo(ClassInfoParam).then((res) => {
            let { success, data, msg } = res
            if (success) {
              getSchoolClass(this.editClassInfo.schoolId).then((res) => {
                let { code, data, message } = res
                this.examRoom = data
              })
              this.$message.success('编辑班级成功！')
              this.dialogEditClass = false
            } else {
              this.$message.error(msg)
            }
          })
        } else {
          return false
        }
      })
    },
    //判断是否为数字类型
    isNumber(num) {
      return /^[0-9]+.?[0-9]*$/.test(num)
    },
  },
}
</script>
<style lang="scss" scoped>
.demo-form-inline {
  margin-top: 20px;
}

.el-form-item {
  margin-bottom: 20px;
}

.addPlace {
  .addAll {
    div:not(:first-child) {
      margin-top: 10px;
    }

    .el-button {
      margin-top: 2%;
      margin-left: 7%;
    }
  }

  .spanText {
    display: inline-block;
    text-align: right;
    width: 70px;
  }

  .el-input {
    width: 85%;
    margin-left: 15px;
  }
}

.special {
  .outLayer {
    height: 250px;
    width: 85%;
    border: solid 1px #797979;
    display: inline-block;
    margin-left: 15px;
    overflow: auto;
  }

  .outLayer::-webkit-scrollbar {
    width: 5px;
  }

  .roomCard {
    display: inline-block;
    position: relative;
    height: 35px;
    width: 220px;
    text-align: center;
    line-height: 35px;
    margin-top: 5px;
    margin-left: 10px;
    border: 1px solid #1890ff;
    background-color: #1890ff;
    border-radius: 5px;
    overflow: hidden;
    .cardText {
      color: #f0eeff;
      width: 190px;
      white-space: nowrap;
      display: inline-block;
      cursor: pointer;

      &:hover {
        color: var(--primary-color);
        border-color: currentColor;
      }
    }

    .close {
      width: 18px;
      height: 18px;
      cursor: pointer;
      float: right;
      position: relative;
      overflow: hidden;
      margin-top: 8px;
      margin-right: 10px;

      &::after {
        width: 100%;
        position: absolute;
        height: 1.5px;
        background: #fff;
        content: '';
        top: 9px;
        left: 0;
        transform: rotate(134deg);
        -ms-transform: rotate(134deg);
        -moz-transform: rotate(134deg);
        -webkit-transform: rotate(134deg);
        -o-transform: rotate(134deg);
      }

      &::before {
        width: 100%;
        position: absolute;
        height: 1.5px;
        background: #fff;
        content: '';
        top: 9px;
        right: 0;
        transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        -moz-transform: rotate(45deg);
        -webkit-transform: rotate(45deg);
        -o-transform: rotate(45deg);
      }
    }
  }
}
</style>
